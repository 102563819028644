<template>
  <div>
    <div class="row mt-2">
      <div class="col-12 col-lg-6 mt-2">
        <b-input
          type="date"
          v-model="data"
          class="text-center"
        ></b-input>
      </div>
      <div class="col-12 col-lg-6 mt-2">
        <EmpresaCombo
          :selected="empresaSelecionada"
          :selecionar="changeEmpresa"
          :comTodos="true"
        ></EmpresaCombo>
      </div>
    </div>
    <hr />
    <b-card>
      <div class="row">
        <div class="col-10">
          <div class="text-left">Produto</div>

          <v-select
            :options="produtos"
            label="nome"
            v-model="editObj.produto"
          />
        </div>
        <div class="col-2">
          <div class="text-left">
            Quantidade
            {{
              editObj && editObj.produto && editObj.produto.tipo_unidade
                ? ` (${editObj.produto.tipo_unidade})`
                : ""
            }}
          </div>

          <b-input
            type="number"
            v-model="editObj.quantidade"
            class="text-center"
            pattern="[0-9]*"
          ></b-input>
        </div>
      </div>
      <b-button
        :variant="editObj && editObj.id ? 'warning' : 'primary'"
        @click="addProduto"
        class="mt-2"
        block
      >
        <b-spinner
          v-if="adicionando"
          small
          label="Aguarde..."
        ></b-spinner>
        <span v-if="editObj && editObj.id">Editar</span>
        <span v-else>Adicionar</span>
      </b-button>
    </b-card>
    <div class="row mt-2">
      <div class="col-12">
        <div
          v-for="(item, key) in agrupped"
          :key="key"
        >
          <div class="d-flex justify-content-between mt-5">
            <h2 class="text-left ml-2 mt-4">
              {{ item.fornecedor || "Sem fornecedor" }}
            </h2>
            <div class="d-flex align-items-end">
              <div class="mx-5">
                <h2>{{ item.vl_total | currencyMask }}</h2>
              </div>
              <b-button
                variant="primary"
                @click="editProduto(item)"
                style="height: 50px"
                size="md"
              >
                Gerar Pedido
              </b-button>
            </div>
          </div>
          <b-table
            striped
            hover
            ref="tbRef"
            id="tbRef"
            selectable
            :items="item.produtos"
            :fields="[
              {
                key: 'produto',
                label: 'Produto',
                sortable: true,
                class: 'text-left',
              },
              {
                key: 'empresa',
                label: 'Empresa',
                class: empresaSelecionada <= 0 ? 'd-' : 'd-none',
                sortable: true,
              },
              { key: 'quantidade', label: 'Quantidade', sortable: true },
              { key: 'vl_unitario', label: 'Vl. Unitário', sortable: true },
              { key: 'vl_total', label: 'Total', sortable: true },
              { key: 'status', label: 'Situação', sortable: true },
              { key: 'opc', label: '' },
            ]"
            :busy="loading"
            @row-clicked="editProduto"
          >
            <template #cell(empresa)="{ item }">
              {{ item.empresa.nome }}
            </template>
            <template #cell(produto)="{ item }">
              <div v-if="item.produto">
                {{ item.produto.nome }}
              </div>
              <div v-if="item.ingrediente">
                {{ item.ingrediente.ingrediente }}
              </div>
            </template>
            <template #cell(quantidade)="{ item }">
              {{ item.quantidade }} {{ item.tipo_unidade }}
            </template>
            <template #cell(vl_unitario)="{ item }">
              {{ item.vl_unitario | currency }}
            </template>
            <template #cell(vl_total)="{ item }">
              {{ item.vl_total | currency }}
            </template>
            <template #cell(opc)="{ item }">
              <b-button
                v-if="item.status == 'pendente'"
                variant="info"
                class="mr-2"
                @click="alterarFornecedor(item)"
                >
                  <i class="fa fa-people-carry"></i>
                </b-button
              >
              <b-button
                v-if="item.status == 'pendente'"
                variant="danger"
                @click="remover(item)"
                >
                  <i class="fa fa-trash"></i>
                </b-button
              >
            </template>
          </b-table>
        </div>
        <div
          v-if="loading"
          class="text-center p-5"
        >
          <b-spinner /><br />
          aguarde...
        </div>
        <div
          v-if="!loading && (!list || list.length <= 0)"
          class="text-center p-5"
        >
          <b>Nenhuma solicitação de produto / ingrediente</b>
        </div>
      </div>
    </div>
    <b-modal id="modal-fornecedor" title="Alterar Fornecedor" @ok="alterarFornecedor">
      <b-form-group label="Fornecedor">
        <v-select
          :options="fornecedores"
          label="nome"
          v-model="fornecedorSelecionado"
        />
      </b-form-group>

    </b-modal>
  </div>
</template>

<script>
import EmpresaCombo from "@/components/empresa/EmpresaCombo.vue";
import moment from "moment";
import EstoqueLib from "@/libs/EstoqueLib";
export default {
  components: {
    EmpresaCombo,
  },
  props: {},
  mounted() {
    this.carregarProdutos();
    console.log(this.user.tipo);
    if (this.user.tipo != "Administrador") {
      this.empresaSelecionada = this.user.cod_empresa;
    } else {
      this.empresaSelecionada = -1;
    }
    this.loadData();
  },
  data() {
    return {
      empresaSelecionada: null,
      data: moment().format("YYYY-MM-DD"),
      produtos: [],
      loading: false,
      editObj: {
        produto: null,
        quantidade: 1,
      },
      list: [],
      adicionando: false,
    };
  },
  watch: {
    empresaSelecionada() {
      this.loadData();
    },
    data() {
      this.loadData();
    },
  },
  computed: {
    agrupped() {
      return this.list.reduce((acc, item) => {
        let has = acc.find((x) => x.fornecedor_id == item.fornecedor_id);
        if (!has) {
          acc.push({
            fornecedor_id: item.fornecedor_id,
            fornecedor: item.fornecedor,
            vl_total: item.vl_total,
            produtos: [item],
          });
        } else {
          has.vl_total += item.vl_total;
          has.produtos.push(item);
        }

        return acc;
      }, []);
    },
  },
  methods: {
    alterarFornecedor(item)
    {
      if (item && item.id) {
        this.editObj = item;
        this.fornecedorSelecionado = item.fornecedor_id;
        this.$bvModal.show("modal-fornecedor");
      }
    },
    getFornecedor(id) {
      let forn = this.produtos.find((p) => p.fornecedor_id == id);

      return forn && forn.fornecedor ? forn.fornecedor.nome : "";
    },
    async remover(items) {
      let dlg = await this.$swal({
        title: "Deseja realmente remover este produto?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
      if (!dlg.isConfirmed) {
        return;
      }
      console.log(items);
      let res = await EstoqueLib.requisicaoEstoqueStore({
        id: items.id,
        deleted_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      console.log("res add", res);
      if (res && res.success) {
        this.$swal({
          title: "Produto removido com sucesso!",
          icon: "success",
          variant: "success",
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          timer: 2000,
        });
      }
      this.cancelar();
    },
    editProduto(p) {
      if (this.editObj && this.editObj.id) {
        this.cancelar();
        return;
      }
      console.log(p);
      this.editObj.produto = {
        cod_produto: p.cod_produto,
        cod_ingrediente: p.cod_ingrediente,
        nome: p.produto ? p.produto.nome : p.ingrediente.ingrediente,
        tipo_unidade: p.tipo_unidade,
      };
      this.editObj.quantidade = p.quantidade;
      this.editObj.id = p.id;
      this.editObj.cod_empresa = p.cod_empresa;
      this.$refs.tbRef.clearSelected();
      this.$refs.tbRef.refresh();
      this.$forceUpdate();
    },
    async carregarProdutos() {
      this.loading = true;
      this.produtos = await EstoqueLib.getProdutos({});
      console.log("prods", this.produtos);
      setTimeout(() => {
        this.$forceUpdate();
        // if (this.editObj && this.editObj.id) {
        //   this.obj = this.editObj;
        //   console.log("obj", this.obj);
        //   if (this.obj.produtos) {
        //     this.editObj = this.obj.produtos;
        //   }
        // }
        this.loading = false;
      }, 1000);
    },
    changeEmpresa(empresa) {
      this.empresaSelecionada = empresa ? empresa.cod_empresa : -1;
    },
    async addProduto() {
      console.log("a", this.editObj);

      if (!this.editObj || !this.editObj.id) {
        if (!this.empresaSelecionada || this.empresaSelecionada <= 0) {
          this.$swal({
            title: "Selecione uma empresa",
            icon: "warning",
            variant: "exclamation",
            toast: true,
            showConfirmButton: false,
            position: "top-end",
            timer: 2000,
          });
          return;
        }
      }
      if (!this.editObj.produto) {
        this.$swal({
          title: "Selecione um produto",
          icon: "warning",
          variant: "exclamation",
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          timer: 2000,
        });
        return;
      }
      if (!this.editObj.quantidade || this.editObj.quantidade <= 0) {
        this.$swal({
          title: "Informe uma quantidade",
          icon: "warning",
          variant: "exclamation",
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          timer: 2000,
        });
        return;
      }

      // this.list.push({
      //   cod_produto: this.editObj.produto.cod_produto,
      //   cod_ingrediente: this.editObj.produto.cod_ingrediente,
      //   produto: this.editObj.produto.nome,
      //   quantidade: this.editObj.quantidade,
      //   status: "Solicitado",
      //   opc: ``,
      // });
      this.adicionando = true;
      console.log("prd", Object.assign({}, { ...this.editObj }));
      let res = await EstoqueLib.requisicaoEstoqueStore({
        cod_produto: this.editObj.produto.cod_produto,
        cod_ingrediente: this.editObj.produto.cod_ingrediente,
        cod_usuario: this.user.cod_usuario,
        cod_empresa:
          this.editObj && this.editObj.cod_empresa
            ? this.editObj.cod_empresa
            : this.empresaSelecionada,
        data: this.data,
        quantidade: this.editObj.quantidade,
        id: this.editObj.id,
        tipo_unidade: this.editObj.produto.tipo_unidade,
        fornecedor_id: this.editObj.produto.fornecedor_id,
        vl_unitario: this.editObj.produto.preco_custo,
        vl_total: this.editObj.produto.preco_custo * this.editObj.quantidade,
      });
      console.log("res add", res);
      if (res && res.success) {
        this.$swal({
          title: "Produto adicionado / atualizado com sucesso!",
          icon: "success",
          variant: "success",
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          timer: 2000,
        });
        this.loadData();
      }
      this.adicionando = false;
      this.cancelar();
    },
    cancelar() {
      this.editObj.produto = null;
      this.editObj.quantidade = 1;
      this.editObj.id = null;
      this.loadData();
    },
    async loadData() {
      console.log("start load");
      this.loading = true;
      this.list = [];
      console.log("asda", this.empresaSelecionada);
      if (this.empresaSelecionada && this.data) {
        let res = await EstoqueLib.requisicaoEstoqueGET({
          cod_empresa:
            this.empresaSelecionada && this.empresaSelecionada > 0
              ? this.empresaSelecionada
              : undefined,
          data: this.data,
          deleted_at: "null",
        });

        console.log("loadData", res);

        if (res && res.success) {
          this.list = res.data;
        }
        // this.$forceUpdate();
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
